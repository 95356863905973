import React from "react"

const NotFoundPage = () => (
  <div>
    <h1>404</h1>
    <p>Pagina niet gevonden</p>
  </div>
)

export default NotFoundPage
